import React, { useState, createContext } from "react"

const SessionContext = createContext(null)

const SessionProvider = ({ children }) => {
    const [sessionUser, setSessionUser] = useState(null)
    const [bypassed, setBypassed] = useState(false)

    const session = { user: sessionUser, setUser: setSessionUser, bypassed: bypassed, setBypassed: setBypassed }
    return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
}

export default SessionContext
export { SessionProvider }
