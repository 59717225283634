import React from "react"
import SessionContext, { SessionProvider } from "components/Common/SessionContext"
import FirebaseContext, { FirebaseProvider } from "components/Common/FirebaseContext"

export const wrapRootElement = ({ element }) => {
    return (
        <SessionProvider>
            <SessionContext.Consumer>
                {session => (
                    <FirebaseProvider>
                        <FirebaseContext.Consumer>{firebase => element}</FirebaseContext.Consumer>
                    </FirebaseProvider>
                )}
            </SessionContext.Consumer>
        </SessionProvider>
    )
}
